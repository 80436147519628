/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;500;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gotu&display=swap');

html, body, #root {
  height: 100%;
  font-family: 'Work Sans', sans-serif;
  color: rgb(32, 32, 32);
  background-image: url("./Images/paper-texture.png");
  background-color: rgb(221, 212, 196);
}

* {
  box-sizing: border-box;
}

.contain {
  max-width: 960px;
  margin: auto;
}