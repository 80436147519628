.panel {
    padding: 80px 40px;
    min-height: 90%;
    display: flex;
}

.panel .title {
    font-weight: 900;
    letter-spacing: 1.25px;
    font-size: 2em;
    line-height: 1.3em;
    margin-bottom: 40px;
    text-transform: uppercase;
    column-span: all;
}


.panel .columns {
    column-width: 50%;
    column-count: 1;
    column-gap: 40px;
}

@media (max-width: 600px) {
    .panel .columns {
        column-count: 1;
    }
}

.panel img {
    width: 100%;
    display: block;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 10px 10px 0 #0000000a;
}

.panel p {
    margin-bottom: 10px;
    line-height: 1.5em;
}

.panel p:last-child() {
    margin-bottom: 0;
}

@media (max-width: 600px) {
    .panel .title {
        font-size: 1.5em;
    }
    .panel p {

    }
}

.panel.academy,
.panel.design {
    color: #b34555;
    background: #e8e6e7;
}

.panel.marketing {
    background: #772d38;
    color: #FFF;
}

.panel.multimedia {
    background-color: #111;
    color: white;
}

.panel.form {
    background-image: url("../../Images/dark-texture.png");
    background-color: rgb(32, 32, 32);
    color: white;
}
