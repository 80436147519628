.header {
    position: fixed;
    width: 100%;
    z-index: 999;
    display: flex;
    align-items: center;
    padding: 20px;
}

.header .logo {
    flex: 1;
    flex-shrink: 2;
}

.header .logo img {
    height: 30px;
}

.header .nav-bar {
    flex: 1;
    flex-grow: 2;
    text-align: right;
}

@media (max-width: 600px) {
    .header .logo {
        display: none;
    }

    .header .nav-bar {
        text-align: center;
    }
}

.nav-bar a {
    margin-right: 20px;
    cursor: pointer;
    line-height: 2em;
    color: #FFF;
    border-bottom: solid 2px #FFF;
    transition: all;
    transition: 0.15s ease all;
}

.nav-bar a:last-child {
    margin-right: 0;
}

.nav-bar a:hover {
    opacity: 0.6;
}

@media (max-width: 600px) {
    .nav-bar {
        text-align: center;
    }
    .nav-bar a {
        font-size: 0.8em;
    }
}

/* @media (max-width: 400px) {

    .nav-bar a {
        font-size: 0.6em;
    }
} */
