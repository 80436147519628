.panel.form .title {
    text-align: center;
}

.panel.form form {
    max-width: 400px;
    margin: auto;
    break-inside: avoid-column;
}

.panel.form label {
    display: block;
    margin-bottom: 10px;
    font-size: 0.8em;
    text-transform: uppercase;
}

.panel.form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    background-color: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    -webkit-appearance: none;
    border-radius: 0;
    color: white;
}

.panel.form input:last-child {
    margin-bottom: 0;
}

.panel.form input[type="file"], .panel.form input[type="submit"] {
    color: white;
    padding: 20px;
    cursor: pointer;
}
.panel.form input[type="file"] {}

.panel.form input[type="submit"] {
    text-transform: uppercase;
    font-weight: 900;
    transition: 0.15s ease all;
    line-height: 1.5em;
    pointer-events: none;
    opacity: 0.3;
    background-color: white;
    color: black;
}

.panel.form input[type="submit"]:hover {
    background-color: black;
    color: white;
}

.panel.form input[type="submit"].active {
    opacity: 1;
    pointer-events: all;
}