.landing-wrapper {
    overflow: hidden;
}

@media(min-width: 769px) {
  .landing {
    background-image: url("../../Images/collage.jpg");
  }
}

@media(max-width: 768px) {
  .landing {
    background-image: url("../../Images/mobile.jpg");
  }
}

.landing {
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 80px 40px;
}

.landing * {
  color: #FFF;
}

.landing .logo {
    width: 180px;
    margin-bottom: 20px;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .rotating {
    -webkit-animation: rotating 30s linear infinite;
    -moz-animation: rotating 30s linear infinite;
    -ms-animation: rotating 130s0s linear infinite;
    -o-animation: rotating 30s linear infinite;
    animation: rotating 30s linear infinite;
  }

.landing h1 {
    font-family: 'Gotu', sans-serif;
    width: auto;
    display: inline-block;
    max-width: 600px;
    line-height: 1.5em;
    text-align: center;
    font-size: 3em;
    margin-bottom: 40px;
    /* background-color:rgba(255, 255, 0, 0.8); */
    /* color: rgb(159, 28, 54); */
}

.landing h2 {
    line-height: 1.5em;
    max-width: 500px;
    font-size: 1.2em;
    margin-bottom: 40px;
    border-left: 5px solid black;
    padding-left: 20px;
}

@media (max-width: 600px) {
    .landing .logo {
        width: 120px;
    }

    .landing h1 {
        font-size: 1.3em;
        margin-bottom: 20px;
    }
    .landing h2 {
        font-size: 1em;
    }
}

.down-arrow {
    position: absolute;
    bottom: 20px;
    padding: 20px;
    opacity: 0.3;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    cursor: pointer;
}

.bounce-7 {
    animation-name: bounce-7;
    animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
}

@keyframes bounce-7 {
    0%   { transform: scale(1,1)      translateY(0); }
    10%  { transform: scale(1.1,.9)   translateY(0); }
    30%  { transform: scale(.9,1.1)   translateY(-20px); }
    50%  { transform: scale(1.05,.95) translateY(0); }
    57%  { transform: scale(1,1)      translateY(-7px); }
    64%  { transform: scale(1,1)      translateY(0); }
    100% { transform: scale(1,1)      translateY(0); }
}
