.footer {
    text-align: center;
    padding: 80px 20px;
}

.footer .wax-stamp {
    width: 120px;
    margin-bottom: 40px;
    opacity: 0.8;
}

.footer .copyright {
    text-transform: uppercase;
    opacity: 0.2;
    font-weight: 900;
    font: 1.5em;
    line-height: 1.5em;
}